.status {

  .status__success {
    font-size: 16px;
    color: #64A338;
    background-color: #B0DC91B2 !important;
  }
  .status__in_progress {
    font-size: 16px;
    color: #e1b400;
    background-color: #FFF3C5B2  !important;
  }
  .status__cancel {
    font-size: 16px;
    color: #E03B24;
    background-color: #FFB1A6B2  !important;
  }
}
