.pager {
  .page-item:first-child .page-link {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .page-item:last-child .page-link {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .page-item.active .page-link {
    background-color: black;
    border-color: black;
  }
  .page-link {
    color: black;
  }
  .page-link:hover {
    color: black;
    background-color: #eeeeee;
  }
  .page-link:focus {
    color: black;
    box-shadow: none;
  }
}
