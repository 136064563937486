.auth-card {
  width: 20rem;
  border: none !important;

  h1, h2, h3, h4, h5, h6 {
    font-family: 'espumapro_book', serif;
  }

  button {
    width: 100%;
    height: 44px;
    border-radius: 35px;
  }

  input {
    height: 40px;
    border: none;
    border-radius: 10px;
    background: #F2F2F2;

    &:focus {
      background: #F2F2F2;
    }
  }

  .alert {
    border: none;
    border-radius: 10px;
    text-align: center;
  }
}
