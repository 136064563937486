.search-field {
  border: 1px solid #000000;
  border-radius: 10px;
  overflow: hidden;
  align-items: center !important;

  .input-group-text {
    border: none;
    border-radius: 0;
    background: transparent;
  }

  .form-control {
    border: none;
    border-radius: 0;
    background: transparent;
    padding: 0.375rem 0.375rem;

    &:focus {
      border: none;
    }
  }

  .btn-close {
    margin-right: 5px;
  }
}
