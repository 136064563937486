.client-header {
  min-height: 110px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-phone, &-logout {
    font-family: 'espumapro_book';
    font-size: 20px;
    color: black;
    text-decoration: none;

    &:hover{
      color: black;
    }
  }
  &-phone {
    margin-right: 1.5rem;
  }
}

.table-responsive {
  border-radius: 10px;
  border: 1px solid #c1c1c1;
  margin-bottom: 1rem;

  th {
    font-family: 'raleway-bold', sans-serif;
    font-size: 16px;
    text-align: center;
    vertical-align: middle;
  }
  td {
    padding: 15px 0.5rem !important;
  }
}
