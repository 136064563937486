.profile-card {
  font-family: 'espumapro_book', serif;
  border-radius: 10px !important;
  margin-bottom: 1rem;

  .card-title {
    font-size: 38px;
  }

  p {
    font-size: 22px;
    margin-bottom: 0.375rem;
  }
}
