.App {
  text-align: center;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}
body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
body {
  margin: 0px;
  font-family: 'raleway-regular', sans-serif;
}

.section {
  background-position: center center;
  background-size: cover;
}

.text-center {
  text-align: center;
}

.section-container, .content-block {
  margin: 0 auto;
}

.section-content {
  color: #666;
  background-position: center center;
  background-repeat: no-repeat;
}

.content-block__header {
  font-family: 'espumapro_book';
  text-align: center;
  color: black;
}

.column-header {
  font-family: 'espumapro_book';
  font-weight: bold;
  color: black;
}

#contacts__block .content-block {
  text-align: center;
}

.footer-menu li {
  display: inline-block;
}

.footer-menu a {
  color: #666;
}

.section-image__header {
  color: white;
}

#go__block .section-image__header {
  color: black;
}

.history-button {
  position: relative;
}

.history-button:before {
  position: absolute;
  background-image: url('../../assets/images/arrow-forward.svg');
  background-repeat: no-repeat;
  background-position: right center;
  right: 25px;
  content: '';
  height: 100%;
  width: 25px;
  top: 0px;
}

.history-button:hover:before {
  animation: arrows .75s ease-out;
}

.black-button {
  background-color: black;
  display: inline-block;
  color: white;
  border-radius: 35px;
  font-family: 'raleway-regular';
  font-size: 18px;
  cursor: pointer;
  line-height: 18px;
  padding: 22px 93px;
  letter-spacing: 0.1em;
  background-image: url('../../assets/images/arrow-forward-white.svg');
  background-repeat: no-repeat;
  margin-top: 16px;
  background-position: right 7% center;
}

.logo {
  display: none;
  height: 60px;
  background-image: url('../../assets/images/logo-black.png');
  background-repeat: no-repeat;
}

.section-header.white .logo {
  background-image: url('../../assets/images/apcg_logo_white_desktop.svg');
}

.menu-wrapper {
  position: fixed;
  background-color: white;
  z-index: 6;
  box-shadow: 0px 5px 21px 0px rgba(0, 0, 0, 0.2);
  height: 100%;
  transition: all ease-in-out .5s;
  font-family: 'espumapro_book';
  top: 0px;
}

.menu-wrapper li {
  list-style-type: none;
}

.menu-wrapper ul {
  padding-left: 0px;
}

.history-title {
  font-family: 'espumapro_book';
}

@keyframes arrows {
  from {
    right: 25px;
    opacity: 1;
  }

  25% {
    right: 15px;
    opacity: .5;
  }

  49% {
    right: 10px;
    opacity: 0;
  }

  50% {
    right: 70px;
    opacity: .5;
  }

  100% {
    right: 30px;
    opacity: 1;
  }

}

@keyframes history_left {
  from {
    left: 0px;
    opacity: 1;
  }

  25% {
    left: -5px;
    opacity: .5;
  }

  49% {
    left: -10px;
    opacity: 0;
  }

  50% {
    left: 80px;
    opacity: .5;
  }

  to {
    left: 0px;
    opacity: 1;
  }
}

@keyframes history_right {
  from {
    right: 0px;
    opacity: 1;
  }

  25% {
    right: -5px;
    opacity: .5;
  }

  49% {
    right: -10px;
    opacity: 0;
  }

  50% {
    right: 80px;
    opacity: .5;
  }

  to {
    right: 0px;
    opacity: 1;
  }
}

.history-close__wrapper {
  text-align: center;
}

.history-close {
  display: inline-block;
  position: relative;
}

@media (max-width:330px) {

  #histories {
    height: 90vw;
  }

}

@media (max-width:360px) {

  #histories {
    height: 95vw;
  }

}

@media (max-width:375px) {

  #histories {
    height: 100vw;
  }

}

@media (max-width:415px) {

  #histories {
    height: 110vw;
  }

}

@media (max-width:1023px) {

  #histories {
    overflow: scroll;
  }

  .menu-wrapper {
    right: 0px;
    width: 70%;
    padding-left: 10%;
    font-size: 30px;
    right: -85%;
    top: 88px;
  }

  .menu-wrapper a {
    color: black;
    text-decoration: none;
  }

  .menu-wrapper li {
    margin-bottom: 35px;
  }

  .menu-wrapper li:first-of-type {
    margin-top: 45px;
  }

  .header-apps {
    display: none;
  }

  .section-header {
    position: fixed;
    top: 0px;
    width: 100%;
    height: 88px;
    background-color: white;
    z-index: 1;
    background-image: url('../../assets/images/apcg-logo-black.svg');
    background-position: 6vw 20px;
    background-size: 48px;
    background-repeat: no-repeat;
  }

  .header-phone {
    position: fixed;
    right: 0px;
    top: 34px;
    z-index: 2;
    font-size: 20px;
    font-family: 'espumapro_book';
    margin-right: 71px;
  }

  .header-phone a {
    color: black;
    text-decoration: none;
  }

  .history-wrapper {
    position: fixed;
    z-index: 5;
    background-color: white;
    width: 80%;
    height: 100%;
    margin: auto;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    display: none;
    opacity: 0;
    transition: opacity .5s ease-in-out;
    padding: 7%;
  }

  .history-wrapper.active {
    display: block;
    opacity: 1;
    transition: opacity .5s ease-in-out;
    width: 100vw;
  }

  .history-title {
    text-align: center;
    font-size: 35px;
    margin-top: 12px;
    margin-bottom: 25px;

  }

  #histories_switcher {
    position: absolute;
    top: 49px;
    width: 86vw;
  }

  #history_left {
    background-image: url(../../assets/images/arrow-back.svg);
    background-repeat: no-repeat;
    left: 0px;
    height: 30px;
    width: 50%;
    position: absolute;
    background-position: left center;
  }

  #history_right {
    background-image: url(../../assets/images/arrow-forward.svg);
    background-repeat: no-repeat;
    right: 0px;
    height: 30px;
    width: 50%;
    background-position: right center;
    top: 0px;
    position: absolute;
  }

  .history_item {
    display: none;
    color: #666;
    font-size: 20px;
    line-height: 32px;
  }

  .history_item.active {
    display: block;
  }

  .section-image {
    height: 120vw;
    position: relative;

  }

  #sky__block {
    background-position: left 35% center;
  }

  #go__block {
    background-position: left 100% center;
  }

  #despair__block, #believe__block {
    background-position: left center;
  }

  .section-content {
    padding: 45px 6vw;
    font-size: 20px;
    line-height: 32px;
    color: #666;
    background-size: 50vw;
  }

  .content-block__header {
    font-size: 35px;
    margin-bottom: 25px;
  }

  .column.right {
    margin-top: 35px;
  }

  #contacts__block {
    position: relative;
  }

  .footer-menu {
    background-color: #f5f5f5;
    position: absolute;
    font-size: 16px;
    left: 0px;
    width: 100%;
    text-align: center;
  }

  .footer-menu ul {
    margin: 0px;
    padding: 10px 6vw;
  }

  .footer-menu li {
    margin-right: 7px;
  }

  .contacts-icons {
    margin-bottom: 72px;
  }

  .menu-bottom {
    position: fixed;
    right: 6vw;
    top: 36px;
    width: 24px;
    height: 20px;
  }

  .menu-bottom span {
    display: block;
    position: absolute;
    height: 2px;
    width: 24px;
    background-color: black;
    opacity: 1;
    right: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
    top: 0px;
  }

  .menu-bottom span:nth-child(2), .menu-bottom span:nth-child(3) {
    top: 8px;
  }

  .menu-bottom span:nth-child(4) {
    top: 16px;
  }

  .menu-bottom.active span:nth-child(1) {
    top: 7px;
    width: 0%;
  }

  .menu-bottom.active span:nth-child(2) {
    transform: rotate(45deg);
  }

  .menu-bottom.active span:nth-child(3) {
    transform: rotate(-45deg);
  }

  .menu-bottom.active span:nth-child(4) {
    top: 7px;
    width: 0%;
  }

  .menu-wrapper.active {
    right: 0px;
  }

  #believe__block {
    margin-top: 88px;
  }

  #main-logo {
    padding-top: 88px;
  }

  .history-button__wrapper {
    text-align: center;
    position: absolute;
    bottom: 80px;
    width: 100%;
  }

  .history-button {
    width: 63vw;
    display: inline-block;
    max-width: 296px;
    height: 56px;
    background-color: white;
    line-height: 56px;
    font-size: 21px;
    border-radius: 32px;
  }

  .history-button:before {
    right: 18px;
  }

  .section-image__header {
    font-size: 7vw;
    line-height: 1.6;
    font-family: 'espumapro_book';
    text-align: center;
    padding: 0px 6vw;
    padding-top: 40vw;
  }

  .section-image__header span {
    font-size: 11vw;
    display: block;
    line-height: 1.2;
  }

  #despair__block .section-image__header {
    padding-top: 100px;
  }

  #despair__block .history-button__wrapper {
    bottom: 40px;
  }

  #sky__block .section-image__header {
    padding-top: 120px;
  }

  .history-close__wrapper {
    text-align: center;
  }

  .history-close {
    border: 1px solid black;
    display: inline-block;
    width: 63vw;
    display: inline-block;
    max-width: 296px;
    height: 56px;
    background-color: white;
    line-height: 56px;
    font-size: 21px;
    border-radius: 32px;
    background-image: url(../../assets/images/icon-close-button.svg);
    background-position: left 20px center;
    background-repeat: no-repeat;
    margin-top: 8px;
    margin-bottom: 16px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
  }
}

@media (min-width:767px) and (max-width: 1023px) {

  .header-phone {
    margin-right: 13vw;
  }

}

@media (min-width: 1024px) {

  .section-container {
    width: 1000px;
  }

  #main-logo img {
    min-width: 155px;
  }

  .logo {
    width: 264px;
    cursor: pointer;
  }

  .content-block {
    width: 950px;

  }

  .content-block__header {
    font-size: 60px;
    margin-bottom: 100px;
  }

  .section-content {
    background-size: 320px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 32px;
  }

  .one-column {
    text-align: center;
  }

  .one-column p:first-of-type {
    margin-bottom: 55px;
  }

  .column {
    display: inline-block;
    vertical-align: top;
    width: 44%;
  }

  .column.left {
    padding-right: 5%;
  }

  .column.right {
    padding-left: 5%;
  }

  .column-header {
    margin-bottom: 37px;
  }

  .contacts-icons {
    margin-top: 40px;
  }

  #contacts__block .section-container {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .footer-menu {
    position: absolute;
    bottom: 50px;
    font-size: 14px;
    width: 100%;
  }

  .footer-menu ul {
    padding-left: 0px;
    text-align: center;
  }

  .footer-menu li {
    margin-right: 11%;
  }

  .footer-menu li:last-of-type {
    margin-right: 0px;
  }

  .section-image__header {
    font-size: 70px;
    text-align: center;
  }

  .section-image .section-container {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .section-image__header span {
    font-family: 'espumapro_book';
    font-size: 97px;
    display: block;
  }

  .history-button__wrapper {
    position: absolute;
    bottom: 17%;
    text-align: center;
    width: 100%;
    left: 0px;
  }

  .history-button {
    display: inline-block;
    font-size: 18px;
    line-height: 18px;
    padding: 22px 113px;
    background-color: white;
    border-radius: 35px;
    cursor: pointer;
    letter-spacing: 0.1em;
  }

  .section-header {
    position: fixed;
    top: 0px;
    width: 100%;
    padding-top: 25px;
    z-index: 5;
    transition: all .5s;
  }

  #main-logo {
    position: absolute;
    top: 25%;
    width: 100%;
    text-align: center;
    left: 0px;
  }

  .section-header .section-container {
    position: relative;
  }

  .header-phone {
    position: absolute;
    right: 0px;
    top: 0px;
    padding-top: 10px;
  }

  .menu-bottom {
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
    width: 32px;
    height: 22px;
    position: relative;
    display: inline-block;
    top: 2px;
  }

  .menu-bottom span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: black;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
    top: 0px;
  }

  .section-header.white .menu-bottom span {
    background-color: white;
  }

  .menu-bottom span:nth-child(2), .menu-bottom span:nth-child(3) {
    top: 10px;
  }

  .menu-bottom span:nth-child(4) {
    top: 20px;
  }

  .header-phone a {
    display: inline-block;
    color: black;
    text-decoration: none;
    font-size: 20px;
    line-height: 22px;
    font-family: 'espumapro_book';
    margin-right: 35px;
    letter-spacing: 0.04em;
  }

  .section-header.white .header-phone a {
    color: white;
  }

  .header-logo a {
    margin-right: 30px;
  }

  .menu-wrapper {
    width: 355px;
    right: -420px;
  }

  .menu-wrapper.active {
    right: 0px;
  }

  .slide {
    transform: translate3d(-646px, 0, 0);
    position: relative;
    left: 0px;
    perspective: 1000px;
    transition: transform 0.5s;
  }

  .section-image {
    transition: all .5s;
  }

  .slide:after {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0px;
    top: 0px;
    background-color: rgba(0,0,0,0.3);
    content: '';
  }

  .header-slide {
    transform: translate3d(-646px, 0, 0);
    perspective: 1000px;
    transition: transform 0.5s;
  }

  #menu-close {
    height: 23px;
    width: 23px;
    margin-bottom: 76px;
    margin-top: 55px;
    background-image: url('../../assets/images/icon-close-button.svg');
    background-size: cover;
    cursor: pointer;
    transition: all ease-in-out .5s;
  }

  #menu-close:hover {
    transform: rotate(180deg);
  }

  .menu-wrapper {
    padding-left: 45px;
  }

  .menu-wrapper li {
    margin-bottom: 64px;
    font-size: 30px;
    cursor: pointer;
  }

  .menu-wrapper a {
    color: black;
    text-decoration: none;
    transition: all .5s ease-in-out;
  }

  .menu-wrapper a:hover {
    color: #0f375b;
  }

  .history-wrapper {
    position: fixed;
    right: 0px;
    height: 100%;
    width: 540px;
    padding-right: 60px;
    transition: all 0.5s;
    visibility: hidden;
  }

  .history-wrapper.active {
    visibility: visible;
    transition: all 0.5s;
  }

  .history-title {
    font-size: 25px;
    color: #0f375b;
    margin-bottom: 35px;
    margin-top: 135px;
  }

  .history-close {
    border-radius: 35px;
    border: 1px solid black;
    padding: 22px 118px;
    cursor: pointer;
  }

  .history-close:after {
    position: absolute;
    height: 100%;
    top: 0px;
    left: 45px;
    width: 17px;
    background-image: url('../../assets/images/icon-close-button.svg');
    background-position: left center;
    background-repeat: no-repeat;
    content: '';
    transition: all .5s ease-in-out;
    display: none;
  }

  .history-wrapper.active .history-close:after {
    display: block;
  }

  .history-close:hover:after {
    transform: rotate(180deg);
    transition: all .5s ease-in-out;
  }

  #histories_switcher {
    position: relative;
    height: 80px;
  }

  #history_left {
    position: absolute;
    left: 0px;
    width: 50%;
    height: 100%;
    cursor: pointer;
  }

  #history_left:after {
    position: absolute;
    content: '';
    width: 110px;
    height: 100%;
    top: 0px;
    left: 0px;
    background-image: url('../../assets/images/history-back.png');
    background-repeat: no-repeat;
    background-position: left center;
  }

  #history_left:hover:after {
    animation: history_left .75s ease-out;
  }

  #history_right {
    position: absolute;
    right: 0px;
    width: 50%;
    height: 100%;
    cursor: pointer;
  }

  #history_right:after {
    position: absolute;
    content: '';
    width: 110px;
    height: 100%;
    top: 0px;
    right: 0px;
    background-image: url('../../assets/images/history-forward.png');
    background-repeat: no-repeat;
    background-position: right center;
  }

  #history_right:hover:after {
    animation: history_right .75s ease-out;
  }

  .history_item {
    color: #4d4d4d;
    font-size: 20px;
    line-height: 32px;
    display: none;
    margin-bottom: 70px;
  }

  .history_item.active {
    display: block;
  }

  #histories {
    height: 290px;
  }

  #menu-shadow {
    position: fixed;
    z-index: 5;
    height: 100%;
    width: 100%;
    left: 0px;
    top: 0px;
    display: none;
  }

  #menu-shadow.active {
    display: block;
  }
}

@media (min-width: 1300px) {

  .section-container {
    width: 1300px;
  }

  .footer-menu li {
    margin-right: 15%;
  }

}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {

  .column {
    display: block;
    width: auto;
    padding: 0px 4% !important;
  }

  .column-header {
    margin-bottom: 7px;
  }

  .column.right {
    margin-top: 15px;
    margin-bottom: 70px;
  }

  .content-block__header {
    margin-bottom: 40px;
  }

  #programms__block {
    justify-content: flex-end;
  }

  .footer-menu {
    text-align: center;
  }

}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {

  .section-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  #believe__block {
    display: block;
  }

  .section-image__header {
    padding-top: 0px !important;
  }

}


.reg-form {
  button {
    width: 60%;
    min-height: 52px;
    border-radius: 35px;
    font-family: 'raleway-regular', sans-serif;
    font-size: 16px;
    letter-spacing: 1.6px;
  }

  .form-control {
    height: 40px;
    border: none !important;
    border-radius: 10px !important;
    background: #F2F2F2 !important;

    &:focus {
      background: #F2F2F2;
    }
  }

  .form-check-input {
    border-radius: 3px !important;
    border-width: 2px !important;
    border-color: #434343 !important;
    background-color: white !important;
  }

  .form-check-input:checked[type=checkbox] {
    background-blend-mode: difference !important;
  }
}
