.datepicker {
  border: 1px solid #000000;
  border-radius: 10px;
  align-items: center !important;

  .react-datepicker {
    font-family: 'raleway-regular', sans-serif;
    border-radius: 10px;

    &__month-container {
      float: none;
    }
    &__header {
      border-top-left-radius: 10px !important;
      border-top-right-radius: 10px !important;
    }
    &__current-month {
      font-family: 'raleway-bold', sans-serif;
    }
    &__day--in-range, &__day--keyboard-selected, &__day--keyboard-selected:hover, &__day--in-range:hover {
      background-color: black;
    }
    &__day--in-selecting-range:not(&__day--in-range, &__month-text--in-range, &__quarter-text--in-range, &__year-text--in-range) {
      background-color: #4c4c4c;
    }
    &__navigation-icon--previous::before, &__navigation-icon--next::before {
      top: 13px;
    }
  }

  &-container {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    height: 36px;

    .react-datepicker-wrapper {
      padding-right: 6px;
    }
  }

  .input-group-text {
    border: none;
    border-radius: 0;
    background: transparent;
  }

  .form-control {
    border: none;
    border-radius: 0;
    background: transparent;
    padding: 0.375rem 0.375rem;

    &:focus {
      border: none;
    }
  }

  .btn-close {
    margin-right: 5px;
  }
}
