.sidebar {
  height: 100vh;
  border-right: 1px solid #C1C1C1;

  .nav-link, .nav-link:hover, .nav-link:active, .nav-link:focus {
    color: black;
    background: white;
  }

  .nav-link {
    height: 44px;

    &.active { filter: invert(1) }
    &-ico {
      width: 30px;
      margin-right: 15px;
    }
  }

}
