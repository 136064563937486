@font-face {
  font-family: 'raleway-regular';
  src: url('./assets/fonts/raleway-regular-webfont.eot');
  src: url('./assets/fonts/raleway-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/raleway-regular-webfont.woff2') format('woff2'),
  url('./assets/fonts/raleway-regular-webfont.woff') format('woff'),
  url('./assets/fonts/raleway-regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'raleway-bold';
  src: url('./assets/fonts/raleway-bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'espumapro_book';
  src: url('./assets/fonts/espumapro-book-webfont.eot');
  src: url('./assets/fonts/espumapro-book-webfont.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/espumapro-book-webfont.woff2') format('woff2'),
  url('./assets/fonts/espumapro-book-webfont.woff') format('woff'),
  url('./assets/fonts/espumapro-book-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* make the customizations */
$enable-transitions: false;
$enable-shadows: false;
$enable-smooth-scroll: false;
$font-family-sans-serif: 'raleway-regular', sans-serif;
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1512px
);
$warning: #fccc21;
$danger: #ea5641;
$success: #7bb457;

/* import bootstrap to set changes */
@import "../node_modules/bootstrap/scss/bootstrap";

@import "../node_modules/react-datepicker/dist/react-datepicker.min.css";

textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active
{
  outline:0px !important;
  -webkit-appearance:none;
  box-shadow: none !important;
}
